<template>
  <b-alert
    :variant="alertVariant"
    show
  >
    <div class="alert-body text-center">
      <div class="mt-1">
        <FeatherIcon icon="InfoIcon" />
      </div>
      <div class="mb-1">
        {{ alertText }}
      </div>
      <div
        v-if="routeUrl"
        class="mb-1"
      >
        <b-button
          size="sm"
          :variant="alertVariant"
          :to="routeUrl"
        >
          Görüşmeyi Git
        </b-button>
      </div>
    </div>
  </b-alert>
</template>

<script>
import { BAlert, BButton } from 'bootstrap-vue'

export default {
  name: 'AlertBox',
  components: {
    BAlert,
    BButton,
  },
  props: {
    routeUrl: {
      type: String,
      default: null,
    },
    alertText: {
      type: String,
      default: null,
    },
    alertVariant: {
      type: String,
      default: 'primary',
    },
  },
}
</script>
