<template>
  <b-list-group-item>
    <div class="font-weight-bold text-primary">
      {{ itemLabel }}
    </div>
    <div v-if="!button">
      {{ itemValue }}
    </div>
    <b-button
      v-else
      variant="outline-danger"
      :href="itemValue"
      size="sm"
      class="mt-1"
      target="_blank"
    >
      İlanı Görüntüle
    </b-button>
  </b-list-group-item>
</template>
<script>
import { BButton, BListGroupItem } from 'bootstrap-vue'

export default {
  name: 'ListItem',
  components: {
    BButton,
    BListGroupItem,
  },
  props: {
    itemLabel: {
      type: String,
      required: true,
    },
    itemValue: {
      type: String,
      required: true,
    },
    button: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
